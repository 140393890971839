import { Button, Card, Col, Row } from 'react-bootstrap'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import FullDealCard from '../../components/FullDealCard'
import { getCheapestPrice } from '@/utility'

function ChooseCity ({ deals, totalRows, loadMoreDeals, showDates, defaultImage }) {

  const { t } = useTranslation()
  const auth = useAuth()


  return (
    <>
      <div className="container p-md-2 p-0">
        <Row className="mt-md-4 mt-0 heading-space">
          <Col className="col-md-6 col-12">
            <div className="button-label primary heading-text">
              {t('pages.deals.choose_deal')}
            </div>
          </Col>
          <Col className="col-md-6 col-12 d-flex justify-content-start justify-content-md-end">
            <div className="button-label light shadow heading-text">
              {t('common.total_price_from', { price: getCheapestPrice(deals), currency: auth.user?.currency?.symbol })}
            </div>
          </Col>
        </Row>
        <div className="p-4 p-md-0">
          <p className="w-100 text-md-start text-center my-4">{t('pages.deals.here_what_we_have_found_for_you', {location: deals[0]?.location?.destination})}</p>
          <Row>
            {
              deals?.map((deal, index) => {
                return (
                  <Col className="col-lg-4 col-md-6 col-12 mb-4" key={deal.id}>
                    <FullDealCard
                      deal={deal}
                      hideDescription={true}
                      linkToDeal={true}
                      defaultImage={defaultImage}
                      selected={index === 0}
                      selectedText={index === 0 ? "Best Value" : false}
                    />
                  </Col>
                )
              })
            }
          </Row>
          {
            totalRows > deals.length ?

              <Row className="mb-3">
                <Col className=" col-12 d-grid gap-2">
                  <Button variant="dark" onClick={() => loadMoreDeals()}>
                    <h6 className="my-1 heading-text">{t('pages.deals.buttons.see_more_options')}</h6>
                  </Button>
                </Col>
              </Row>
              : null
          }
        </div>
      </div>
    </>
  )
}

export default ChooseCity