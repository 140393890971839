import { useTranslation } from 'react-i18next'
import LOCATION from '../constants/Location'
import { Col, Row } from 'react-bootstrap'
import UserIcon from '../shared/components/Icons/UserIcon'
import HeartIcon from '../shared/components/Icons/HeartIcon'
import GraphStarIcon from '../shared/components/Icons/GraphStarIcon'
import BagIcon from '../shared/components/Icons/BagIcon'
import DownloadIcon from '../shared/components/Icons/DownloadIcon'
import TripIcon from '../shared/components/Icons/TripIcon'
import GrassesIcon from '../shared/components/Icons/GrassesIcon'
import StarIcon from '../shared/components/Icons/StarIcon'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useAuth } from '../services/Auth'
import SunIcon from '../shared/components/Icons/SunIcon'
import {useEffect, useState} from 'react'

function TravelArea () {
  const auth = useAuth()
  const { t } = useTranslation()

  const [showTop15, setShowTop15] = useState(false);

  const getDeals = () => {
    return auth.getRequest(`${LOCATION.DEALS_WEEK.API.path}/top-15`)
        .then(response => {
          if (response.data?.data?.id === undefined) {
            setShowTop15(false);
          } else {
            setShowTop15(true);
          }
        })
        .catch(error => {
          setShowTop15(false);
        })
  }

  useEffect(() => {
    getDeals();
  }, []);

  const areas = [
    {
      name: t('pages.trips.start_your_journey'),
      link: LOCATION.TRIPS.START_YOUR_JOURNEY.path,
      icon: <TripIcon color="#1A1414" size={45}/>,
      max: false,
      disabled: false,
    },
    {
      name: t('pages.trips.saved_trips'),
      link: LOCATION.TRIPS.MY_SAVED_TRIPS.path,
      icon: <HeartIcon color="#1A1414" size={45}/>,
      max: false,
      disabled: false,
    },
    {
      name: t('pages.deals.top_15'),
      link: `${LOCATION.DEALS.LIST.path}/top-15`,
      icon: <GraphStarIcon color="#1A1414" size={45}/>,
      max: false,
      disabled: !showTop15,
    },
    {
      name: t('pages.profile.edit_profile'),
      link: LOCATION.PROFILE.EDIT.path,
      icon: <UserIcon color="#1A1414" size={45}/>,
      max: false,
      disabled: false,
    },
    {
      name: t('pages.profile.preferences'),
      link: LOCATION.PROFILE.PREFERENCES.path,
      icon: <UserIcon color="#1A1414" size={45}/>,
      max: false,
      disabled: auth.user.user_type !== 'travel_max',
    },
    {
      name: t('pages.trips.beach_deals'),
      link: `${LOCATION.DEALS.LIST.path}/list/beach-deals`,
      icon: <SunIcon color="#1A1414" size={45}/>,
      max: false,
      disabled: auth.user.user_type !== 'travel_max',
    },
    {
      name: t('pages.trips.under_price_deals', { price: '£200' }),
      link: `${LOCATION.DEALS.LIST.path}/list/deal-by-budget`,
      icon: <BagIcon color="#1A1414" size={45}/>,
      max: false,
      disabled: auth.user.user_type !== 'travel_max',
    },
    {
      name: t('pages.travel_resources.title'),
      link: LOCATION.TRAVEL_RESOURCES.DOWNLOAD.path,
      icon: <DownloadIcon color="#1A1414" size={45}/>,
      max: false,
      disabled: auth.user.user_type !== 'travel_max',
    },
    {
      name: t('pages.trips.weekend_trip'),
      link: `${LOCATION.DEALS.LIST.path}/list/weekend-trip`,
      icon: <GrassesIcon color="#1A1414" size={45}/>,
      max: false,
      disabled: auth.user.user_type !== 'travel_max',
    },
    {
      name: t('pages.trips.long_haul'),
      link: `${LOCATION.DEALS.LIST.path}/list/long-haul`,
      icon: <StarIcon color="#1A1414" size={45}/>,
      max: false,
      disabled: auth.user.user_type !== 'travel_max',
    },
  ]

  // useEffect(() => {
  //   localStorage.removeItem('request_data')
  // }, [])

  return (
    <div className="container">

      <Helmet>
        <title>{t('common.app')}</title>
      </Helmet>

      <Row className="mt-5">
        {
          areas.map((area, index) => {
            return (
              <Col className="col-6 col-sm-4 col-lg-3 d-flex justify-content-center travel-grid" key={index}>
                <Link
                  to={area.link}
                  className={`travel-area-tile bg-primary d-flex align-items-center flex-column ${area.disabled ? 'disabled' : ''}`}
                >
                  <span className="tile-icon">{area.icon}</span>
                  <span
                    className="tile-title mt-2 text-break text-uppercase fw-bold text-dark text-center heading-text">{area.name}</span>
                </Link>
              </Col>
            )
          })
        }
      </Row>
    </div>
  )
}

export default TravelArea