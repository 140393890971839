import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import React, { useRef, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@/shared/components/LoadingButton'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import DealSelection from '../../Deals/components/DealSelection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import DealForm from '../../Deals/components/DealForm'
import useGet from '@/hooks/useGet'
import LOCATION from '@/constants/Location'
import LoadingCover from '@/shared/components/LoadingCover'
import FullDealCard from '../../../Deals/components/FullDealCard'

function DealWeekForm ({ dealWeek, saveDealWeek, serverErrors, savingDealWeek, submitLabel, }) {
  const { t } = useTranslation()
  const auth = useAuth()

  const [deals, setDeals] = useState(dealWeek?.deals || [])
  const [dealsCount, setDealsCount] = useState(dealWeek?.deals.filter(_=> _.custom_deal))

  const { data: holidayTypes, loading } = useGet(LOCATION.HOLIDAY_TYPES.API.path)

  const schema = yup.object().shape({
    name: yup.string()
      .required(t('pages.top_15_deals.form.week_name')),
    starting_date: yup.string()
      .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.starting_date') })),
    ending_date: yup.string()
        .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.ending_date') })),
    custom_deals: yup.array()
      .of(yup.object().shape({
        adults: yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.adults') })),
        children: yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
        children_ages: yup.array()
          .typeError(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.children_age') }))
          .when(['children'], function (children, schema) {
            return children && children != 0 ?
              schema.of(yup.number()
                .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.who_is_traveling_adults.children_age') }))
                .transform((value) => (isNaN(value) ? undefined : value)))
              : schema.nullable()
          }),
        holiday_type_id: yup.number()
          .required(t('common.form_validation.is_required', { attribute: t('pages.trips.steps.holiday_type.title') })),
        airport_id: yup.number()
          .required(t('common.form_validation.is_required', { attribute: t('common.airport') })),
        location_id: yup.number()
          .required(t('common.form_validation.is_required', { attribute: t('pages.locations.location') })),
        custom_message: yup.string().nullable(),
        flights: yup.object().shape({
          inbound_airline_code: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.airline_code') })),
          inbound_airline_name: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.airline_name') })),
          inbound_arrival_time: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.arrival_time') })),
          inbound_departure_time: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.departure_time') })),
          outbound_airline_code: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.airline_code') })),
          outbound_airline_name: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.airline_name') })),
          outbound_arrival_time: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.arrival_time') })),
          outbound_departure_time: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.departure_time') })),
          link: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.booking_link') })),
          price: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.total_price') })),
        }),
        hotel: yup.object().shape({
          name: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.hotel_name') })),
          link: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.booking_link') })),
          hotel_teaser: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.hotel_description') })),
          star_rating: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.star_rating') })),
          guest_star_rating: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.guest_star_rating') })),
          price: yup.string()
            .required(t('common.form_validation.is_required', { attribute: t('pages.top_15_deals.form.total_price') })),
        })
      }))
  })

  const mapCustomDeal = (deal) => {

    return {
      id: deal.id,
      trip_request_id: deal.trip_request_id,
      adults: deal.trip_request.adults,
      children: deal.trip_request.children,
      children_ages: deal.trip_request.children_ages,
      holiday_type_id: deal.trip_request.holiday_type_id,
      airport: deal.airport,
      airport_id: deal.airport.id,
      location: deal.location,
      location_id: deal.location.id,
      custom_message: deal.custom_message,
      flights: {
        id:  deal.flight.id,
        inbound_flight_id:  deal.flight.inbound_flight.id,
        outbound_flight_id:  deal.flight.outbound_flight.id,
        inbound_airline_code:  deal.flight.inbound_flight.airline_code,
        inbound_airline_name:  deal.flight.inbound_flight.airline_name,
        inbound_arrival_time:  deal.flight.inbound_flight.arrival_time,
        inbound_departure_time:  deal.flight.inbound_flight.departure_time,
        outbound_airline_code:  deal.flight.outbound_flight.departure_time,
        outbound_airline_name:  deal.flight.outbound_flight.airline_name,
        outbound_arrival_time:  deal.flight.outbound_flight.arrival_time,
        outbound_departure_time:  deal.flight.outbound_flight.departure_time,
        link:  deal.flight.link,
        price:  deal.flight.price,
      },
      hotel: {
        id:  deal.hotel.id,
        name: deal.hotel.name,
        link: deal.hotel.link,
        hotel_teaser: deal.hotel.hotel_teaser,
        star_rating: parseInt(deal.hotel.start_rating),
        guest_star_rating: parseInt(deal.hotel.guest_rating),
        thumbnail_url: deal.hotel.thumbnail_url,
        price: deal.hotel.price,
        hotel_amenities: deal.hotel.hotel_amenities,
        images: deal.hotel.images,
      }
    }

  }

  const {
    handleSubmit,
    register,
    unregister,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: (_ => {
      return {
        name: dealWeek?.name,
        starting_date: dealWeek?.starting_date,
        ending_date: dealWeek?.ending_date,
        published: dealWeek?.published,
        custom_deals: dealWeek?.deals.filter(_=> _.custom_deal).map(mapCustomDeal)
      }
    })(),
    resolver: yupResolver(schema),
  })

  const submitForm = (values) => {
    values.deals = deals.filter(_=> !_.custom_deal).map(_ => _.id)
    saveDealWeek(values)
  }

  const removeDeal = (deal) => {
    let dealsList = deals.filter(_ => _.id !== deal.id)
    setDeals(dealsList)
  }

  const saveDeals = (deal) => {
    let dealsList = [].concat(deals)
    dealsList.push(deal)
    setDeals(dealsList)
  }

  const addNewDeal = () => {
    let deals = [].concat(dealsCount)
    deals.push({ deal: 'count' })
    setDealsCount(deals)
  }

  const removeCustomDeal = (index) => {
    let deals = [].concat(dealsCount)
    deals[index] = null
    setDealsCount(deals)
    unregister(`custom_deals[${index}]`)
  }


  if (loading) return <LoadingCover/>

  return (
    <>
      <Form className="location-form" noValidate onSubmit={handleSubmit(submitForm)}>
        <Form.Group className="my-4" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.week_name')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.week_name') })}
            {...register('name')}
            isInvalid={!!errors.name}
          />

          <Form.Control.Feedback type="invalid">
            {errors.name && errors.name.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="my-4" controlId="formBasicDate">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.starting_date')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="date"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.starting_date') })}
            {...register('starting_date')}
            isInvalid={!!errors.starting_date}
          />

          <Form.Control.Feedback type="invalid">
            {errors.starting_date && errors.starting_date.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="my-4" controlId="formBasicDate">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('pages.top_15_deals.form.ending_date')}
            <span className="text-danger ms-2 small">*</span>
          </Form.Label>
          <Form.Control
            type="date"
            placeholder={t('common.form.placeholder_enter', { attribute: t('pages.top_15_deals.form.ending_date') })}
            {...register('ending_date')}
            isInvalid={!!errors.ending_date}
          />

          <Form.Control.Feedback type="invalid">
            {errors.ending_date && errors.ending_date.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="my-4" controlId="formBasicDate">
          <Form.Check
            inline
            label={t('pages.top_15_deals.form.publish')}
            name="published"
            type="switch"
            {...register('published')}
          />
        </Form.Group>

        {
          dealWeek &&
          <>
            <hr/>
            <h6 className="heading-text mb-4">{t('pages.top_15_deals.assign_existing_deals_to_list')}</h6>
            <Row>
              {
                deals.filter(_=> !_?.custom_deal).map(deal => {
                  return (
                    <Col className="col-12 col-md-6 mb-3">
                      <FullDealCard
                        deal={deal}
                        shortFullCard={true}
                        hideDescription={true}
                        removeDeal={removeDeal}
                        manageCustomMessage={true}
                      />
                    </Col>
                  )
                })
              }
            </Row>

            <Form.Group className="my-4" controlId="formBasicDate">
              <DealSelection
                placeHolder={t('common.search')}
                saveDeals={saveDeals}
                deals={deals}
              />
            </Form.Group>
          </>
        }


        <hr/>
        <h6 className="heading-text mb-4">{t('pages.top_15_deals.create_new_deals_for_list')}</h6>
        {
          dealsCount?.map((_, index) => {
            return _ ? (
              <Card key={index} className="mb-3">
                <Card.Body>
                  <DealForm
                    deal={_}
                    key={index}
                    index={index}
                    watch={watch}
                    removeCustomDeal={removeCustomDeal}
                    register={register}
                    unregister={unregister}
                    setValue={setValue}
                    holidayTypes={holidayTypes}
                    errors={errors?.custom_deals}
                    namePrefix={`custom_deals[${index}].`}
                  />
                </Card.Body>
              </Card>
            ) : null
          })
        }
        <div className="d-grid gap-2">
          <Button
            variant="dark"
            className="text-white"
            onClick={() => addNewDeal()}
          >
            <FontAwesomeIcon className="me-2" icon={faPlus}/>
            {t('pages.top_15_deals.buttons.add_new_deal')}
          </Button>
        </div>

        {
          (serverErrors.length !== 0) &&
          <div className="form-group mb-4">
            {
              serverErrors.map((error, index) =>
                <p className="text-danger font-weight-bold"
                   key={index}>{t(error)}</p>)
            }
          </div>
        }
        <Form.Group className="mt-4">
          <LoadingButton
            className="auth-btn text-white px-5 heading-text text-uppercase"
            loading={savingDealWeek}
            variant="primary"
            titleTranslationKey={submitLabel}
            type="submit"
          />
        </Form.Group>
      </Form>
    </>
  )
}

export default DealWeekForm