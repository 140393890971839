import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {Button, Col, Form, Row} from 'react-bootstrap'
import React, {useEffect, useState, useRef} from 'react'
import PageHeader from '@/layouts/components/PageHeader'
import {Link, useHistory} from 'react-router-dom'
import steps from '../Request/process_steps/steps'
import LOCATION from '../../../constants/Location'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowLeftLong, faArrowRightLong, faPlus, faStar} from '@fortawesome/free-solid-svg-icons'
import HotelFilters from "../Request/components/HotelFilters";
import {useAuth} from "../../../services/Auth";

function WhatWillMakeItSpecial({
                                   modelEditor,
                                   handleCancel,
                                   handleConfirm,
                                   holidayTypes
                               }) {

    const {t} = useTranslation()

    const auth = useAuth()

    const history = useHistory()

    let step = steps.find(_ => _.name === 'what-will-make-it-special')

    let storeValues = localStorage.getItem('trip_values') ?? null

    const [tripValues, setTripValues] = useState(storeValues ? JSON.parse(storeValues) : {})

    let nextStepLink = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/summary`
    let backStepLink = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/when`

    const continueActionRef = useRef(null)

    const holidayType = holidayTypes.find(_ => _.id === tripValues?.holiday_type)

    const {
        handleSubmit,
        register,
        watch,
        setValue,
        unregister,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            filters: tripValues?.filters,
            star_rating: tripValues?.star_rating === null ? null : (tripValues?.star_rating ?? auth?.user?.preferences?.star_rating ?? null),
        }
    })

    const starRating = watch('star_rating');

    const values = watch();

    const nextStep = (values) => {
        localStorage.setItem('trip_values', JSON.stringify({
            ...tripValues,
            filters: values.filters,
            star_rating: values.star_rating,
        }));

        if (!modelEditor) {
            history.push(nextStepLink)
        } else if (handleConfirm) {
            handleConfirm()
        }
    }

    const handleAnyWellRatedAccommodation = () => {
        setValue('star_rating', null);
        nextStep({
            ...values,
            star_rating: null
        });
    }

    useEffect(() => {
        if (!storeValues) {
            history.push(LOCATION.TRIPS.START_YOUR_JOURNEY.path)
        }
    }, [])

    return (
        <>
            {
                !modelEditor &&
                <>
                    <PageHeader
                        className="d-none d-sm-flex"
                        title="Add filters"
                        bgColor="dark"
                        textColor="white"
                        prefixElements={[]}
                        suffixElements={[]}
                    />
                    <div className="w-100 sm-bg d-block d-sm-none" style={
                        {
                            backgroundImage: `url("/assets/images/bg/${step.bg_sm}")`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }
                    }>
                    </div>
                    <PageHeader
                        className="d-block d-sm-none"
                        title="Add filters"
                        bgColor="dark"
                        textColor="white"
                    />
                </>
            }

            <div className="w-100 h-100 d-flex justify-content-center align-items-sm-start" style={
                !modelEditor ? {
                    backgroundImage: `url("/assets/images/bg/${step.bg}")`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                } : null
            }>
                <div className="mt-sm-5 mt-0 p-sm-5 p-3 trip-process-form what-make-special">
                    {/*<h6*/}
                    {/*    className="heading-text fw-bold request-process-heading">{t('pages.trips.steps.what_will_make_it_special.form_heading')}</h6>*/}

                    <Form noValidate onSubmit={handleSubmit(nextStep)}>
                        {/*<HotelFilters*/}
                        {/*    register={register}*/}
                        {/*    unregister={unregister}*/}
                        {/*    errors={errors}*/}
                        {/*    watch={watch}*/}
                        {/*    setValue={setValue}*/}
                        {/*    rooms={tripValues?.filters}*/}
                        {/*    holidayType={holidayType}*/}
                        {/*/>*/}
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mt-4">
                                    <p>Filter by Star Rating</p>
                                    {
                                        [1, 2, 3, 4, 5].map((star, index) => (
                                            <div key={index} className="d-inline-block me-2"
                                                 onClick={() => setValue('star_rating', star === starRating ? null : star)}>
                                                <FontAwesomeIcon
                                                    className={"me-2 star-rating-icon" + (starRating >= star ? ' selected' : '')}
                                                    icon={faStar}/>
                                            </div>
                                        ))
                                    }
                                    {
                                        starRating >= 1 && (
                                            <span className="d-block mt-2">
                                                We will filter hotels that are rated {starRating} stars and above
                                            </span>
                                        )
                                    }
                                    {
                                        starRating === null && (
                                            <span className="d-block mt-2">
                                                We will filter hotels that are well rated
                                            </span>
                                        )
                                    }
                                    <div className="line-separator separator my-3 text-uppercase">{t('common.or')}</div>
                                    <Button onClick={handleAnyWellRatedAccommodation} variant="dark" className="fw-bold d-block text-white w-100" type="button">
                                        <span className="text-uppercase">Any Well Rated Accommodation</span>
                                    </Button>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mt-4 d-grid gap-2 modal-buttons">
                            {
                                modelEditor ?
                                    <div className="d-flex justify-content-between w-100">
                                        <Button
                                            variant="secondary"
                                            className="heading-text me-2"
                                            onClick={() => handleCancel()}
                                        >
                                            {t('common.buttons.cancel')}
                                        </Button>
                                        <Button ref={continueActionRef} className="fw-bold heading-text" type="submit">
                                            <span className="text-uppercase">{t('common.buttons.save')}</span>
                                        </Button>
                                    </div> :
                                    <>
                                        <Button ref={continueActionRef}
                                                className="fw-bold d-block heading-text" type="submit">
                                            <span className="text-uppercase">{t('common.buttons.continue')}</span>
                                        </Button>

                                        <Link to={backStepLink}
                                              className="btn btn-light-1 btn-lg fw-bold d-block mt-2 heading-text"
                                              type="button">
                                            <span className="text-uppercase">{t('common.buttons.back')}</span>
                                        </Link>
                                    </>
                            }
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default WhatWillMakeItSpecial