import { useTranslation } from 'react-i18next'
import React, { useEffect, useState, useRef } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Button, Form } from 'react-bootstrap'
import BudgetSelection from '../Request/components/BudgetSelection'
import { useAuth } from '../../../services/Auth'
import { Link, useHistory, useLocation } from 'react-router-dom'
import PageHeader from '../../../layouts/components/PageHeader'

import steps from '../Request/process_steps/steps'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import LOCATION from '../../../constants/Location'

function Budget ({ modelEditor, handleConfirm, handleCancel }) {

  const { t } = useTranslation()

  const auth = useAuth()

  const history = useHistory()
  const route = useLocation()

  let nextStepLink = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/holiday-type`

  let step = steps.find(_ => _.name === 'budget')

  let storeValues = localStorage.getItem('trip_values') ?? null

  const [tripValues, setTripValues] = useState(storeValues ? JSON.parse(storeValues) : {
    budget: auth.user?.preferences.budget ?? [0, 2500],
  })

  const [maxSelectedBudget, setMaxSelectedBudget] = useState(tripValues?.budget)

  const continueActionRef = useRef(null)

  const schema = yup.object().shape({
    budget: yup.array()
      .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.max_budget.title') })),
  })

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...tripValues,
      budget: maxSelectedBudget,
    },
    resolver: yupResolver(schema),
  })

  const nextStep = (tripValues) => {

    localStorage.setItem('trip_values', JSON.stringify(tripValues))
    if (!modelEditor) {
      history.push(nextStepLink)
    } else if (handleConfirm) {
      handleConfirm()
    }
  }

  return (
    <>
      {
        !modelEditor &&
        <>

          <PageHeader
            className="d-none d-sm-flex"
            title={t(step.title)}
            bgColor="dark"
            textColor="white"
            prefixElements={[]}
            suffixElements={[]}
          />
          <div className="w-100 sm-bg d-block d-sm-none" style={
            {
              backgroundImage: `url("/assets/images/bg/${step?.bg_sm}")`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }
          }>
          </div>
          <PageHeader
            className="d-block d-sm-none"
            title={t(step?.title)}
            bgColor="dark"
            textColor="white"
          />
        </>
      }

      <div className="w-100 h-100 d-flex justify-content-center align-items-sm-start" style={!modelEditor ?
        {
          backgroundImage: `url("/assets/images/bg/${step?.bg}")`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        } : {}
      }>
        <div className="mt-sm-5 mt-0 p-sm-5 p-3 trip-process-form">
          <h6 className="heading-text">{t('pages.trips.steps.budget.form_heading')}</h6>
          <p className="text-smaller mt-3">{t('pages.trips.steps.budget.budget_sub_heading')}</p>
          <Form noValidate onSubmit={handleSubmit(nextStep)}>
            <Form.Group className="my-4 pb-4 position-relative px-2">
              <BudgetSelection
                errors={errors}
                setValue={setValue}
                values={tripValues}
                setMaxBudget={setMaxSelectedBudget}
                currency={auth.user?.currency?.symbol ?? '£'}
              />
            </Form.Group>
            <Form.Group className="my-4 pt-1 mb-0 position-relative">
              <p className="mb-0">{t('pages.trips.steps.budget.budget_statement', {
                min: maxSelectedBudget[0],
                max: maxSelectedBudget[1],
                currency: auth.user?.currency?.symbol ?? '£'
              })}</p>
            </Form.Group>
            <Form.Group className="mt-4 d-grid gap-2">
              {
                modelEditor ?
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="secondary"
                      className="heading-text me-2"
                      onClick={() => handleCancel()}
                    >
                      {t('common.buttons.cancel')}
                    </Button>
                    <Button ref={continueActionRef} className="fw-bold heading-text" type="submit">
                      <span className="text-uppercase">{t('common.buttons.save')}</span>
                    </Button>
                  </div> :
                  <Button ref={continueActionRef} className="fw-bold d-block heading-text" type="submit">
                    <span className="text-uppercase">{t('common.buttons.continue')}</span>
                  </Button>
              }
            </Form.Group>

          </Form>
        </div>
      </div>
    </>
  )
}

export default Budget