import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../services/Auth'
import { Link, useHistory, useLocation } from 'react-router-dom'
import LOCATION from '../../../constants/Location'
import steps from '../Request/process_steps/steps'
import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import PageHeader from '../../../layouts/components/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faArrowRightLong, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import BudgetSelection from '../Request/components/BudgetSelection'

function HolidayType ({ modelEditor, holidayTypes, handleCancel, handleConfirm }) {
  const { t } = useTranslation()

  const auth = useAuth()

  const history = useHistory()
  const route = useLocation()

  let nextStepLink = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/where-from`
  let backStepLink = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}`

  let step = steps.find(_ => _.name === 'holiday-type')

  let storeValues = localStorage.getItem('trip_values') ?? null

  const [tripValues, setTripValues] = useState(storeValues ? JSON.parse(storeValues) : {})

  const [selectedHolidayType, setSelectedHolidayType] = useState(tripValues?.holiday_type ?? null)
  const [submitError, setSubmitError] = useState(false)


  let userAirports = auth.user?.preferences?.airports?.slice(0, Math.min(2, auth.user?.preferences?.airports?.length))?? [];

  let userLongHaulAirports = auth.user?.preferences?.long_haul_airports?.slice(0, Math.min(2, auth.user?.preferences?.long_haul_airports?.length))?? [];

  const selectHolidayType = (id) => {
    setSubmitError(false)

    if (!id) {
      setSelectedHolidayType('surprise')
      nextStep('surprise')
    } else {
      setSelectedHolidayType(() => id)
      nextStep(id)
    }
  }

  const nextStep = (holidayType) => {

    if (selectedHolidayType != null || holidayType != null) {
      let values = {
        ...tripValues,
        ...{ holiday_type: holidayType ?? selectedHolidayType },
        where_from: ((holidayType ?? selectedHolidayType) === 4 ? userLongHaulAirports : userAirports)?.map(_ => {
          return { value: _.id, label: _.name }
        }),
      }

      localStorage.setItem('trip_values', JSON.stringify(values))
      if (!modelEditor) {
        history.push(nextStepLink)
      } else if (handleConfirm) {
        handleConfirm()
      }

    } else {
      setSubmitError(true)
    }
  }

  useEffect(() => {
    if (!storeValues) {
      history.push(LOCATION.TRIPS.START_YOUR_JOURNEY.path)
    }
  }, [])

  return (
    <>
      {
        !modelEditor &&
        <>
          <PageHeader
            className="d-none d-sm-flex"
            title={t(step.title)}
            bgColor="dark"
            textColor="white"
            prefixElements={[]}
            suffixElements={[]}
          />
          <PageHeader
            className="d-block d-sm-none"
            title={t(step?.title)}
            bgColor="dark"
            textColor="white"
          />
        </>
      }

      <div className="container holiday-type">
        <Row>
          {
            holidayTypes?.filter(holidayType => holidayType?.show_on_trip_request_form)?.map(holidayType => {
              return (
                <Col
                  className="col-6 p-3 position-relative"
                  role="button"
                  onClick={() => selectHolidayType(holidayType.id)}
                  key={holidayType.id}
                >
                  {
                    (holidayType.id === selectedHolidayType) ?
                      <FontAwesomeIcon className="checked-holiday-type" icon={faCheckCircle}/>
                      :
                      null
                  }
                  <Card className="holiday-type-card">
                    <Card.Img variant="top" src={holidayType.thumbnail_url} />
                    <Card.Body>
                      <Card.Title
                        className="heading-text text-primary fw-bold mb-0">{holidayType?.name}</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              )
            })
          }

          {/* SURPRISE ME CARD */}
          {/*<Col*/}
          {/*  className="col-6 p-3 position-relative"*/}
          {/*  role="button"*/}
          {/*  onClick={() => selectHolidayType(false)}*/}
          {/*>*/}
          {/*  {*/}
          {/*    selectedHolidayType === 'surprise' ?*/}
          {/*      <FontAwesomeIcon className="checked-holiday-type" icon={faCheckCircle}/>*/}
          {/*      :*/}
          {/*      null*/}
          {/*  }*/}
          {/*  <Card className="holiday-type-card">*/}
          {/*    <Card.Img variant="top" src={`/assets/images/holidays/surprise.jpeg`}/>*/}
          {/*    <Card.Body>*/}
          {/*      <Card.Title*/}
          {/*        className="heading-text text-primary fw-bold mb-0">{t(`pages.trips.steps.holiday_type.types.surprise`)}</Card.Title>*/}
          {/*    </Card.Body>*/}
          {/*  </Card>*/}
          {/*</Col>*/}
        </Row>
        {
          submitError ?

            <Row>
              <Col>
                <small className="text-danger">{t('pages.trips.steps.holiday_type.submit_error')}</small>
              </Col>
            </Row>
            : null
        }

        {
          !modelEditor &&
          <Link to={backStepLink} className="btn btn-light-1 btn-lg fw-bold d-block mt-2 heading-text" type="button">
            <span className="text-uppercase">{t('common.buttons.back')}</span>
          </Link>
        }
      </div>
    </>
  )
}

export default HolidayType