const Top15Deals = {
  title: 'Top 15 Deals',
  assign_existing_deals_to_list: 'Assign existing Deals to this top 15',
  create_new_deals_for_list: 'Create new Deals',
  // deal_headline: '{{id}} - From {{airportName}} to {{destination}} on {{tripDate}} for {{duration}} days',
  deal_headline: '{{id}} - From {{airportName}} to {{destination}} on {{tripDate}} for {{duration}}, ',
  form: {
    week_name: 'Week Name',
    starting_date: 'Week start date',
    ending_date: 'Week end date',
    search_for_deals: 'Search for deals (by Airport, user or destination)',
    airline_name: 'Airline Name',
    airline_code: 'Airline Code',
    departure_time: 'Departure Time',
    arrival_time: 'Arrival Time',
    outbound_flight: 'Outbound Flight',
    inbound_flight: 'Inbound Flight',
    total_price: 'Total Price',
    total_flight_price: 'Total Flights Price',
    total_hotel_price: 'Total Hotel Price',
    booking_link: 'Booking Link',
    hotel_name: 'Hotel Name',
    hotel_description: 'Description',
    star_rating: 'Star Rating',
    guest_star_rating: 'Guest Rating',
    images: 'Image(s)',
    or_upload_image: 'Or Upload Image',
    remove_image: 'Remove Image',
    hotel_amenities: 'Hotel Amenities',
    hotel_amenities_placeholder: 'Hotel Amenities (Separated by comma)',
    publish: 'Publish',
    add_image: 'Add Image',
  },
  buttons: {
    create_deal_week: 'Create Deal Week',
    update_deal_week: 'Update Deals',
    search_for_deals: 'Search for Deals',
    save_deal_to_top_15_week: 'Save Deal to Top 15 Week',
    remove_deal_to_top_15_week: 'Remove Deal to Top 15 Week',
    add_new_deal: 'Add New Deal',
  },
  notifications: {
    deal_week_created: {
      title: 'Deal week Created',
    },
    deal_week_updated: {
      title: 'Deal week Updated',
    }
  },
  week_name: '{{startDate}} to {{endDate}}',
  headers: {
    deal_form_header: 'Fill in Flights and Hotel information below to create a new deal',
    flights_details: 'Flights Details',
    hotel_details: 'Hotel details',
  }
}
export default Top15Deals