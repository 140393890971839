import { useForm } from 'react-hook-form'
import { Button, Form, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../../services/Auth'
import LOCATION from '../../../../constants/Location'
import { debounce, delay } from 'lodash'

function DealCustomMessageForm ({ deal }) {

  const { t } = useTranslation()

  const auth = useAuth()

  const [saving, setSaving] = useState(false)

  const {
    handleSubmit,
    register,
    unregister,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: (_ => {
      return {
        custom_message: deal?.custom_message,
      }
    })(),
  })

  const submitForm = () => {}

  const updateDealMessage = (customMessage) => {
    setValue(customMessage)
    setSaving(true)
    auth.putRequest(`${LOCATION.DEALS.API.path}/${deal.id}/custom-message`, {
      custom_message: customMessage,
    })
      .then(response => {
        setSaving(false)

      })
      .catch(error => {
        setSaving(false)
      })
  }

  const onChange = (e) => {
    updateDealMessage(e.target.value)
  }

  const debouncedOnChange = debounce(onChange, 1000)
  return (
    <Form className="location-form" noValidate onSubmit={handleSubmit(submitForm)}>
      <Row className="col col-12 mt-2">
        <Form.Group className="mb-4 col-12" controlId="formBasicName">
          <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
            {t('common.form.custom_message')}
          </Form.Label>
          <Form.Control
            disabled={saving}
            readOnly={saving}
            as="textarea"
            placeholder={t('common.form.placeholder_enter', { attribute: t('common.form.custom_message') })}
            {...register('custom_message')}
            onChange={debouncedOnChange}
            isInvalid={!!(errors && errors.custom_message)}
          />
          <Form.Control.Feedback type="invalid">
            {errors && errors.custom_message && errors.custom_message.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
    </Form>
  )
}

export default DealCustomMessageForm