import React, { createContext, useContext, useEffect, useCallback, useState, useRef } from 'react'
// import { useRollbarPerson, useRollbar } from '@rollbar/react'
import api, {
  setToken, setHandler,
  postRequest,
  getRequest,
  putRequest,
  deleteRequest
} from '../services/Api'
import { useTranslation } from 'react-i18next'
import Pusher from 'pusher-js'
import Push from 'push.js'
import LOCATION from '../constants/Location';
import { usePostHog } from 'posthog-js/react';

const pushKey = process.env.REACT_APP_PUSHER_KEY
const pusherConfig = {}

pusherConfig.enabledTransports = ['ws', 'wss']

if (process.env.REACT_APP_PUSHER_HOST) {
  pusherConfig.wssHost = process.env.REACT_APP_PUSHER_HOST
  pusherConfig.wsHost = process.env.REACT_APP_PUSHER_HOST
}

if (process.env.REACT_APP_PUSHER_CLUSTER) {
  pusherConfig.cluster = process.env.REACT_APP_PUSHER_CLUSTER
}

if (process.env.REACT_APP_PUSHER_PORT) {
  pusherConfig.wssPort = process.env.REACT_APP_PUSHER_PORT
  pusherConfig.wsPort = process.env.REACT_APP_PUSHER_PORT
}

if (process.env.REACT_APP_PUSHER_AUTH_ENDPOINT) {
  pusherConfig.authEndpoint = process.env.REACT_APP_PUSHER_AUTH_ENDPOINT
}

if (process.env.REACT_APP_ENCRYPTED) {
  pusherConfig.encrypted = process.env.REACT_APP_ENCRYPTED === 'true'
}

if (process.env.REACT_APP_USE_TLS) {
  pusherConfig.useTLS = process.env.REACT_APP_USE_TLS === 'true'
  pusherConfig.forceTLS = process.env.REACT_APP_USE_TLS === 'true'
}

const authContext = createContext()
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth ({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}

function trySetUser(){
  let localUser = localStorage.getItem('user') || null
  let user = null;
  if(localUser){
    try{
      user =  JSON.parse(localUser)
    }
    catch (e){

    }
  }

  return user;
}

function useProvideAuth () {

  const { t, i18n } = useTranslation();
  const posthog = usePostHog()

  // const rollbar = useRollbar()

  let localLang = localStorage.getItem('lang') || 'en'
  let token = localStorage.getItem('token') || null

  const [user, setUser] = useState(trySetUser())
  const [language, setLanguage] = useState(localLang)
  const [tokenChecked, setTokenChecked] = useState(false)
  const [errorPage, setErrorPage] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [initialLoad, setInitialLoad] = useState(true)

  const [notifications, setNotifications] = useState([])
  const [newNotification, setNewNotification] = useState([])
  const [pushedNotifications, setPushedNotifications] = useState([])
  const pushedNotificationRef = useRef()
  const dismissedNotificationRef = useRef()

  const [socket, setSocket] = useState(null)
  const [userChannel, setUserChannel] = useState(null)


  useEffect(() => {
    if (user && user?.id && user?.email) {
      posthog?.identify(user?.id, {
        email: user?.email,
      })
    }
  }, [user]);

  const updateUser = (user) => {
    setUser(user)

    if (user && user?.id && user?.email) {
      posthog?.identify(user?.id, {
        email: user?.email,
      })
    }
  }

  const updateErrorPage = (errorPage) => {
    setErrorPage(errorPage)
  }

  const handleResponse = (response) => {
    localStorage.setItem('token', response.data.token)
    localStorage.setItem('user', JSON.stringify(response.data.user))
    setUser(response.data.user)
    setToken(response.data.token)
  }

  const signIn = (payload, headers) => {
    return new Promise((resolve, reject) => {
      postRequest('/login', payload, headers)
        .then(response => {
          handleResponse(response)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const signUp = (payload) => {
    return new Promise((resolve, reject) => {
      postRequest('/sign-up', payload)
        .then(response => {
          handleResponse(response)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const logout = () => {
    return new Promise((resolve, reject) => {
      api.post('/logout')
        .then(response => {
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          resolve(true)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const handleApiErrorResponse = useCallback((error) => {
    if (error?.config?.url?.includes('travelmum') || error?.config?.url?.includes('dev-version') || error?.config?.url?.includes('.test')) {
      if (error?.response) {
        setErrorMessage(null)
        switch (error.response.status || 0) {
          case 401:
            setUser(null)
            setToken(null)
            localStorage.removeItem('user')
            localStorage.removeItem('token')
            setTokenChecked(true)
            return
            break

          case 403:
            setErrorPage(403)
            return
            break

          case 422:
            return
            break

          case 0:
          case 404:
            setErrorPage(error.response.status || 0)
            return
            break

          default:
            setErrorPage(500)
            setErrorMessage(error.response?.data?.message)
        }
      } else if (error?.code === 'ERR_NETWORK') {
        setErrorPage(0)
      }
  }
    // rollbar.error(error)
  })

  const checkToken = useCallback(() => {
    return new Promise((resolve, reject) => {
      getRequest('/handshake')
        .then(response => {
          let data = response.data;
          if (data?.user) {
            localStorage.setItem('user', JSON.stringify(data.user));
            if (data.user && data.user?.id && data.user?.email) {
              posthog?.identify(data.user?.id, {
                email: data.user?.email,
              });
            }
            setUser(data.user)
            setTokenChecked(true)
          }

          if (data?.message === 'Not Logged In') {
            throw 'Not Logged In';
          }

          let notes = data?.notifications?.filter(_ => !!_) ?? []

          if (!(notifications instanceof Array)) {
            setPushedNotifications(notes.map(_ => _.id).concat(pushedNotifications))
          }

          setNotifications(notes)

          resolve(response)
        })
        .catch(error => {
          localStorage.removeItem('user');
          if(window.location.pathname !== '/login'  && window.location.pathname !== '/'){
            localStorage.setItem('intended', window.location.pathname)
          }
          handleApiErrorResponse(error)
          reject(error)
        })
    })
  }, [notifications])

  setToken(token)
  // useRollbarPerson(user)

  useEffect(() => {
    setHandler(handleApiErrorResponse)
    setToken(token)
  }, [])

  const pushQueuedNotifications = useCallback(() => {
    const queue = notifications?.filter && notifications.filter(_ => !pushedNotifications.includes(_?.id)) || []

    for (var index in queue) {
      pushNotification(queue[index])
    }
  }, [notifications])

  useEffect(() => {
    setHandler(handleApiErrorResponse)
    setToken(token)

    pusherConfig.authorizer = ({ name: channel_name }) => ({
      authorize: (socket_id, callback) => {
        api.post(pusherConfig.authEndpoint, { channel_name, socket_id })
          .then((response) => callback(false, { auth: response.data?.auth }))
          .catch(() => callback(true))
      }
    })

    const pusher = new Pusher(pushKey, pusherConfig)

    setSocket(pusher)

    pusher.connection.bind('connected', function () {
      api.defaults.headers.common['X-Socket-ID'] = pusher.connection.socket_id
    })

    if (Push.Permission.DENIED === 'denied') {
      Push.Permission.request(() => {
        pushQueuedNotifications()
      }, () => {
        // console.error('Cannot Push')
      })
    }

    checkToken()
  }, [])

  useEffect(() => {
    if (socket && user) {
      const channelName = `private-App.Models.User.${user.id}`

      const channel = socket.subscribe(channelName)

      setUserChannel(channel)

      return () => {
        setUserChannel(null)
        socket.unsubscribe(channelName)
      }
    }
  }, [socket, user])

  useEffect(() => {
    if (userChannel) {
      const event_name = 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated'
      const push = (notification) => {
        return pushedNotificationRef && pushedNotificationRef.current(notification)
      }

      userChannel.bind(event_name, push)

      const channel = userChannel

      return () => channel.unbind(event_name, push)
    }
  }, [userChannel])

  useEffect(() => {
    if (userChannel) {
      const event_name = 'notification-dismissed'
      const push = (notification) => dismissedNotificationRef && dismissedNotificationRef.current(notification)

      userChannel.bind(event_name, push)

      const channel = userChannel

      return () => channel.unbind(event_name, push)
    }
  }, [userChannel])

  const pushedNotification = useCallback((notification) => {

    if (notification) {

      notifications.unshift(notification)

      const newNotifications = [].concat(notifications)

      setNotifications(newNotifications.filter(_ => !!_))

      let title = t('pages.deals.notifications.deals_found.title')
      let body = t('pages.deals.notifications.deals_found.body')

      if (notification?.canceled) {
        title = t('pages.deals.notifications.no_deal_found_on_request_limit_reached.title')
        body = t('pages.deals.notifications.no_deal_found_on_request_limit_reached.body')
      }
      else if (notification?.has_deals === false) {
        title = t('pages.deals.notifications.no_deal_found_on_request.title')
        body = t('pages.deals.notifications.no_deal_found_on_request.body')
      }

      if( notification?.event_type === 'deal_request_response' && notification.tripRequest.search_completed){
        Push.create(
          title,
          {
            body,
            tag: notification?.tripRequest.id,
            requireInteraction: false,
            onClick: function () {
              window.location.href = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/results/${notification.tripRequest.id}`
              this.close()
            }
          }
        )
      }

      setNewNotification(notification)

    }
  }, [notifications])

  const pushNotification = useCallback((notification) => {
    var data = {}

    setPushedNotifications([notification.id].concat(pushedNotifications))
  }, [pushedNotifications])

  const dismissedNotification = useCallback((notification) => {
    if (notification) {
      const newNotifications = [].concat(notifications)

      setNotifications(newNotifications.filter(_ => !!_ && _.id != notification.id))
    }
  }, [notifications])

  const clearNotification = () => {
    setNewNotification(null)
  }

  useEffect(() => {
    pushedNotificationRef.current = pushedNotification
  }, [pushedNotification])

  useEffect(() => {
    pushQueuedNotifications()
  }, [notifications])

  useEffect(() => {
    dismissedNotificationRef.current = dismissedNotification
  }, [dismissedNotification])

  // Return the user object and auth methods
  return {
    notifications,
    user,
    tokenChecked,
    errorPage,
    errorMessage,
    updateUser,
    language,
    setLanguage,
    signIn,
    signUp,
    logout,
    checkToken,
    postRequest,
    getRequest,
    putRequest,
    deleteRequest,
    updateErrorPage,
    api,
    newNotification,
    clearNotification,
  }
}
