import PageHeader from '@/layouts/components/PageHeader'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import steps from '../Request/process_steps/steps'
import LOCATION from '../../../constants/Location'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import AirportSelection from '../Request/components/AirportSelection'
import { useAuth } from '../../../services/Auth'

function WhereFrom ({
  modelEditor,
  handleCancel,
  handleConfirm,
}) {
  const { t } = useTranslation()

  const auth = useAuth()

  const history = useHistory()
  const route = useLocation()

  let step = steps.find(_ => _.name === 'where-from')

  let storeValues = localStorage.getItem('trip_values') ?? null

  const [tripValues, setTripValues] = useState(storeValues ? JSON.parse(storeValues) : {})

  let nextStepLink = tripValues?.holiday_type === 'surprise' ? `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/when` : `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/where-to`
  let backStepLink = `${LOCATION.TRIPS.START_YOUR_JOURNEY.path}/holiday-type`

  const continueActionRef = useRef(null)

  const schema = yup.object().shape({
    where_from: yup.array()
      .min(1, t('pages.trips.form.validation_message.choose_one_airport'))
      .of(yup.object().shape({
        value: yup.number()
          .required(t('pages.trips.form.validation_message.is_required', { attribute: t('pages.trips.form.labels.where_from.title') })),
        label: yup.string(),
      }))
  })

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    unregister,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: tripValues,
    resolver: yupResolver(schema),
  })

  const nextStep = (values) => {
    localStorage.setItem('trip_values', JSON.stringify(values))


    if (auth?.user && auth.user.user_type !== 'travel_max' && values?.where_from?.length !== 0 && values?.holiday_type !== 4) {
      updateUserAirports(values)
    }

    if (!modelEditor) {
      history.push(nextStepLink)
      // window.location.href = nextStepLink;
    } else if (handleConfirm) {
      handleConfirm()
    }
  }

  const updateUserAirports = (values) => {
    auth.postRequest(`${LOCATION.USERS.API.path}/airports`, {
      where_from: values?.where_from?.map(_ => _.value)
    }).then().catch()
  }

  useEffect(() => {
    if (!storeValues) {
      history.push(LOCATION.TRIPS.START_YOUR_JOURNEY.path)
    }
  }, [])

  return (
    <>
      {
        !modelEditor &&
        <>
          <PageHeader
            className="d-none d-sm-flex"
            title={t(step.title)}
            bgColor="dark"
            textColor="white"
            prefixElements={[]}
            suffixElements={[]}
          />
          <div className="w-100 sm-bg d-block d-sm-none" style={
            {
              backgroundImage: `url("/assets/images/bg/${step.bg_sm}")`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }
          }>
          </div>
          <PageHeader
            className="d-block d-sm-none"
            title={t(step.title)}
            bgColor="dark"
            textColor="white"
          />
        </>
      }

      <div className="w-100 h-100 d-flex justify-content-center align-items-sm-start" style={
        !modelEditor ? {
          backgroundImage: `url("/assets/images/bg/${step.bg}")`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        } : {}
      }>
        <div className="mt-sm-5 mt-0 p-sm-5 trip-process-form">
          <h6
            className="heading-text fw-bold request-process-heading">{t('pages.trips.steps.where_from.single_form_heading')}</h6>
          <Form noValidate onSubmit={handleSubmit(nextStep)}>
            <AirportSelection
              errors={errors}
              unregister={unregister}
              register={register}
              reset={reset}
              setValue={setValue}
              selectedAirports={tripValues?.where_from}
              filter="countries"
              controlled={true}
              holidayType={tripValues?.holiday_type}
              unregisterOnRemove={false}
            />
            <Form.Group className="my-4 d-grid gap-2">

              {
                modelEditor ?
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="secondary"
                      className="heading-text me-2"
                      onClick={() => handleCancel()}
                    >
                      {t('common.buttons.cancel')}
                    </Button>
                    <Button ref={continueActionRef} className="fw-bold heading-text" type="submit">
                      <span className="text-uppercase">{t('common.buttons.save')}</span>
                    </Button>
                  </div> :
                  <>
                    <Button ref={continueActionRef} className="fw-bold d-block btn-lg heading-text" type="submit">
                      <span className="text-uppercase">{t('common.buttons.continue')}</span>
                    </Button>

                    {
                      !modelEditor &&
                      <Link to={backStepLink} className="btn btn-light-1 btn-lg fw-bold d-block mt-2 heading-text" type="button">
                        <span className="text-uppercase">{t('common.buttons.back')}</span>
                      </Link>
                    }
                  </>
              }
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  )
}

export default WhereFrom