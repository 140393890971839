import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Col, Form, Row } from 'react-bootstrap'
import AirportSearch from './AirportSearch'
import LoadingButton from '@/shared/components/LoadingButton'

function UserFilters ({
  searching,
  values,
  loadUsers,
}) {

  const { t } = useTranslation()
  const {
    resetField,
    handleSubmit,
    register,
    setValue,
    watch,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...values
    }
  })

  const onSubmit = (values) => {
    let query = {
      ...values,
      page: 0,
      skip: 0,
      reset: true,
    }
    loadUsers(query)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Form.Group className="col col-sm-6 col-md-3 col-12 mb-2 mb-md-1">
          <Form.Label>
            {t('common.search')}
          </Form.Label>
          <Form.Control
            {...register('search')}
            placeholder={t('common.search_for_anything')}
            name="search"
          />
        </Form.Group>

        <Form.Group className="col col-sm-6 col-md-2 col-12 mb-2 mb-md-1">
          <Form.Group>
            <Form.Label>
              {t('pages.users.form.labels.user_type')}
            </Form.Label>

            <Form.Control
              as="select"
              name="user_type"
              {...register('user_type')}
            >
              <option value="all">{t('common.choose')}</option>
              <option value="travel_lite">{t('pages.users.user_types.travel_lite')}</option>
              <option value="travel_max">{t('pages.users.user_types.travel_max')}</option>
            </Form.Control>
          </Form.Group>
        </Form.Group>

        <Form.Group className="col col-sm-6 col-md-3 col-12 mb-2 mb-md-1">
          <Form.Group>
            <Form.Label>
              {t('common.form.preferred_airport')}
            </Form.Label>
            <AirportSearch
              setValue={setValue}
              placeHolder={t('common.search')}
            />
          </Form.Group>
        </Form.Group>


        <Form.Group className="col col-sm-6 col-md-2 col-12 mb-2 mb-md-1">
          <Form.Group>
            <Form.Label>
              {t('pages.users.form.labels.sort_by')}
            </Form.Label>

            <Form.Control
              as="select"
              name="sort_by"
              {...register('sort_by')}
            >
              <option value="none">{t('common.choose')}</option>
              <option value="expire_desc">Expiring Last</option>
              <option value="expire_asc">Expiring First</option>
              <option value="created_at_desc">Created Newest</option>
              <option value="created_at_asc">Created Oldest</option>
            </Form.Control>
          </Form.Group>
        </Form.Group>
        <Col className="col-sm-6 col-md-2 col-12 mb-2 mb-md-1 d-flex align-items-end">
          <div className="d-grid gap-2 w-100">
            <LoadingButton
              type="submit"
              onlySpinner={true}
              loading={searching}
              className="text-white mt-3 mt-md-0 heading-text"
              titleTranslationKey="common.apply"
            />
          </div>
        </Col>
      </Row>
    </form>
  )

}

export default UserFilters